import type { ProjectActivityStatusEnumType } from '@forgd/supabase'

interface SelectedActivity {
  activity: SuccessResponseBody<'/activities/project/{projectId}/v2'>['advanced'][number]['activities'][number]// PlaybookAdvancedActivity
  category: SuccessResponseBody<'/activities/project/{projectId}/v2'>['advanced'][number] // PlaybookAdvancedActivityGroup
}

export const usePlaybookActivitiesStore = defineStore('playbook-activities', () => {
  const projectId = ref('')
  const toast = useAppToast()
  const selectedActivityId = ref<string | null>()

  const selectedActivity = computed<SelectedActivity | null>(() => {
    if (!selectedActivityId.value || !activities.value) {
      return null
    }

    const category = activities.value.advanced.find(c => c.activities.some(a => a.id === selectedActivityId.value))
    const activity = category?.activities.find(a => a.id === selectedActivityId.value)
    if (!category || !activity) {
      return null
    }

    return {
      activity,
      category,
    }
  })

  const params = computed(() => ({
    path: {
      projectId: projectId.value,
    },
  }))

  const { data: activities, refresh, loading } = useQuery('/activities/project/{projectId}/v2', {
    params,
    options: {
      lazy: true,
    },
  })

  const basicActivitiesCounter = computed(() => {
    return activities.value?.basic.length ?? 0
  })

  const advancedActivitiesCounter = computed(() => {
    return activities.value?.advanced
      .flatMap(category => category.activities)
      .length ?? 0
  })

  const fetchActivities = (id: string) => {
    projectId.value = id
    return refresh()
  }

  async function setActivityStatus(activityId: string, status: ProjectActivityStatusEnumType) {
    // Store the original activities state for potential rollback
    const originalActivities = structuredClone(toRaw(activities.value))

    // Optimistic update
    if (activities.value) {
      activities.value = {
        basic: activities.value?.basic.map(activity => activity.id === activityId
          ? { ...activity, status }
          : activity,
        ),
        advanced: activities.value?.advanced.map(category => ({
          ...category,
          activities: category.activities.map(activity => activity.id === activityId
            ? { ...activity, status }
            : activity,
          ),
        })),
      }
    }

    try {
      const res = await useQuery('/activities/status', {
        method: 'post',
        params: {
          body: {
            projectId: projectId.value,
            activityId,
            status,
          },
        },
        options: {
          toast: true,
        },
      })
      if (res.status.value === 'error') {
        throw new Error('Server update failed')
      }

      toast.success('Status changed successfully')
      await refresh()
    }
    catch {
      // Rollback to original state if server update fails
      activities.value = originalActivities
    }
  }

  function setSelectedActivity(activityId: string | null) {
    selectedActivityId.value = activityId
  }

  return {
    loading,
    basicActivitiesCounter,
    advancedActivitiesCounter,
    activities,
    selectedActivity,
    fetchActivities,
    setActivityStatus,
    setSelectedActivity,
  }
})
